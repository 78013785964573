import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getpushNotificationToken, onMessageListener } from "../Utils/firebase";

const Notification = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ body: "" });

  if (!localStorage.getItem("fb")) {
    getpushNotificationToken(setTokenFound);
  }

  useEffect(() => {
    getpushNotificationToken(setTokenFound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notification?.body) {
      toast(notification?.body, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
  }, [notification]);

  onMessageListener()
    .then((payload: any) => {
      setNotification({
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <></>;
};

export default Notification;

class GlobalLoaderModal {
  isLoading = false;
}

class LoadingModel {
  isLoading: boolean = false;
  mainLoading: boolean = false;
}
class LoginModel {
  email: string = "";
  password: string = "";
}
class ForgotPasswordModel {
  email: string = "";
}
class ResetPasswordModel {
  token: string = "";
  newPassword: string = "";
}

class SignUpModel {
  name: string = "";
  email: string = "";
  password: string = "";
}

class ProfileModel {
  userType: string = "";
  businessName: string = "";
  location: { [key: string]: string | [] } | null = null;
  companyDetail: { [key: string]: any } | null = null;
}

class ProfileDetailsModel {
  profileDetailsData: any = [];
}

class DropDownListsModel {
  roles: [] = [];
  skills: [] = [];
}

class AdminTeamPermissionModel {
  emails: [] = [];
}

class TeamMembersDataModel {
  teamMembersData: any = [];
  memberData: any = {};
  sortBy: string = "";
  sortOrder: number = 1;
  pageNo: number = 1;
  limit: number = 10;
  userType: string = "";
  search: string = "";
  searchResult: boolean = false;
}

class DeleteAdminTeamPermissionModel {
  userId: string = "";
}

class EditTeamPermissionModel {
  teamIds: { [key: string]: any } = {
    _id: "string",
    updatedRole: "",
    email: "string",
  };
}

class SearchModel {
  skills: [] = [];
  industrys: [] = [];
}

class ServiceProviderSearchModel {
  serviceProviderSearch: any = [];
}

class CompanySearchFromBookNowModel {
  companySearchData: any = [];
}

class RatesDetailsModel {
  ratesDetailsData: any = [];
}

class RFQProjectModel {
  projectType: { [key: string]: any } | null = null;
  projectDescription: string = "";
  mediaFile: { [key: string]: any } | null = null;
  invitedCompany: { [key: string]: any } = {
    companyId: "string",
    businessName: "",
    businessProfilePicture: "string",
  };
}

class ClientJobsTableModel {
  clientJobsDataAll: any = [];
  clientJobsData: any = [];
  clientJobsData1: any = [];
  clientJobsData2: any = [];
  clientJobsData3: any = [];
  clientJobsData4: any = [];
  pageNo: number = 1;
  limit: number = 10;
  TotalPage: number = 1;
  TotalPage1: number = 1;
  TotalPage2: number = 1;
  TotalPage3: number = 1;
  TotalPage4: number = 1;
  filterServiceProvider: any = [];
  filterRequestType: any = [];
  All: boolean = false;
  Emergency_Callout: boolean = false;
  Maintenance_Service: boolean = false;
  Project_Services: boolean = false;
  RFQ: boolean = false;
}

class JobDetailsModel {
  jobDetailsData: any = [];
  otherJobDetailsData: any = [];
  tabValue: number = 2;
  bookedDetailsData: any = [];
  isEdit: boolean = false;
  customerData: any = [];
  files: any = []; //........
  jobId: any = "";
}

class CountJobModel {
  countJob: any = [];
}

class statusChangeModel {
  statusValue: string = "ALL";
}

class serviceProviderModel {
  serviceProviderList: any = [];
  filterServiceProvider: any = [];
  filterRequestType: any = [];
}

class scheduleBookingModel {
  scheduleBooking: any = [];
  pageNo: number = 1;
  limit: number = 3;
  filterRequestType: any = [];
}

class savedCompanyListingModel {
  savedCompanyList: any = [];
}

class NotificationListingModel {
  notificationListData: any = [];
}

class CompanyDetailsModel {
  companyDetailsData: any = [];
}

class CommentsModel {
  CommentsData: any = [];
}

class ReducersModel {
  globalLoaderReducer: GlobalLoaderModal = new GlobalLoaderModal();
  logInReducer: LoginModel = new LoginModel();
  forgotPasswordReduce: ForgotPasswordModel = new ForgotPasswordModel();
  resetPasswordReduce: ResetPasswordModel = new ResetPasswordModel();
  signupReducer: SignUpModel = new SignUpModel();
  profileReducer: ProfileModel = new ProfileModel();
  profiledetailsdataReducer: ProfileDetailsModel = new ProfileDetailsModel();
  dropDownReducer: any;
  adminTeamPermissionReducer: AdminTeamPermissionModel =
    new AdminTeamPermissionModel();
  teamMembersDataReducer: TeamMembersDataModel = new TeamMembersDataModel();
  deleteAdminTeamPermissionReducer: DeleteAdminTeamPermissionModel =
    new DeleteAdminTeamPermissionModel();
  editTeamPermissionReducer: EditTeamPermissionModel =
    new EditTeamPermissionModel();
  searchReducer: SearchModel = new SearchModel();
  serviceProviderSearchReducer: ServiceProviderSearchModel =
    new ServiceProviderSearchModel();
  companySearchReducer: CompanySearchFromBookNowModel =
    new CompanySearchFromBookNowModel();
  ratesDetailsReducer: RatesDetailsModel = new RatesDetailsModel();
  clientJobsTableReducer: ClientJobsTableModel = new ClientJobsTableModel();
  jobDetailsReducer: JobDetailsModel = new JobDetailsModel();
  CountJobReducer: CountJobModel = new CountJobModel();
  statusChangeReducer: statusChangeModel = new statusChangeModel();
  serviceProviderReducer: serviceProviderModel = new serviceProviderModel();
  scheduleBookingReducer: scheduleBookingModel = new scheduleBookingModel();
  savedCompanyListingReducer: savedCompanyListingModel =
    new savedCompanyListingModel();
  notificationListingReducer: NotificationListingModel =
    new NotificationListingModel();
  companyDetailsReducer: CompanyDetailsModel = new CompanyDetailsModel();
  commentsModelReducer: CommentsModel = new CommentsModel();
}

export {
  ReducersModel,
  GlobalLoaderModal,
  LoadingModel,
  LoginModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  SignUpModel,
  ProfileModel,
  ProfileDetailsModel,
  DropDownListsModel,
  RFQProjectModel,
  AdminTeamPermissionModel,
  TeamMembersDataModel,
  DeleteAdminTeamPermissionModel,
  EditTeamPermissionModel,
  SearchModel,
  ServiceProviderSearchModel,
  CompanySearchFromBookNowModel,
  ClientJobsTableModel,
  JobDetailsModel,
  CountJobModel,
  statusChangeModel,
  serviceProviderModel,
  RatesDetailsModel,
  scheduleBookingModel,
  savedCompanyListingModel,
  NotificationListingModel,
  CompanyDetailsModel,
  CommentsModel,
};

const Pathname = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgotpassword",
  RESEND_LINK: "/resendemail",
  RESET_PASSWORD: "/user/forgot-password",
  SIGNUP: "/signup",
  TERMS: "/terms",
  PRIVACYPOLICY: "/privacyPolicy",
  VERIFY: "/verifyemail",
  COMPANYPROFILE: "/user/emailverify",
  HEADER: {
    DASHBOARD: "/Home",
    REQUESTS: "/Requests",
    INBOX: "/Inbox",
  },
  DASHBOARD: "/Home",
  SCHEDULEBOOKING: "/ScheduleBooking",
  Settings: {
    base: "/settings",
    Mydetails: "/settings/my_details",
    BusinessProfile: "/settings/Business_profile",
    Aggrements: "/settings/Aggrements",
    Team: "/settings/team",
    Financial: "/settings/Financial",
    Notification: "/settings/Notification",
    Invite: "/settings/Invite",
  },
  RFQProjects: "/RfqProjects",
  EmergencyCallOut: "/EmercengyCallout",
  ProjectService: "/ProjectService",
  MaintenanceServices: "/MaintenanceServices",
  UnderDevelopment: "/underdevelopment",
  MyRequest: "/view_all_request",
  JobDetails: "/jobdetails",
  landing: "/landing",
  SAVEDCOMPANY: "/savedCompany",
  CompanyProfile: "/CompanyProfile",
  underDevelopment: "/underDevelopment",
  Enquiries: "Enquiries",
};
export default Pathname;

import React, { lazy } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "../hoc/publicRoute";
import PrivateRoute from "../hoc/privateRoute";
import Utils from "../Utils";
import Notification from "../components/Notification";

const LandingPage = lazy(() => import("../screen/LandingPage"));
const SavedCompany = lazy(() => import("../screen/LandingPage/savedCompany"));

const Login = lazy(() => import("../screen/login/index"));
const ForgotPassword = lazy(() => import("../screen/login/forgotPassword"));
const ResendLink = lazy(() => import("../screen/login/resendLink"));
const ResetPassword = lazy(() => import("../screen/login/resetPassword"));
const Signup = lazy(() => import("../screen/signup/index"));
const Terms = lazy(() => import("../screen/signup/t&c/terms"));
const PrivacyPolicy = lazy(() => import("../screen/signup/t&c/privacy"));
const VerifyEmail = lazy(() => import("../screen/signup/verifyEmail"));
const CompanyProfile = lazy(() => import("../screen/signup/companyprofile"));
const Dashboard = lazy(() => import("../screen/dashboard/index"));
const ViewAllScheduleBooking = lazy(
  () => import("../screen/dashboard/Home/Booking/ViewAll")
);
const Settings = lazy(() => import("../screen/dashboard/settings/index"));
const Requests = lazy(() => import("../screen/Requests"));
const Logout = lazy(() => import("../screen/UnderDevelopment"));
const MyRequest = lazy(
  () => import("../screen/Requests/Project_RFQ/myRequest")
);
const RFQProject = lazy(() => import("../screen/dashboard/RFQ"));
const EmergencyCallout = lazy(
  () => import("../screen/dashboard/EmergencyCallOut")
);
const MaintenanceServices = lazy(
  () => import("../screen/dashboard/Maintenance_Services")
);
const ProjectSupport = lazy(
  () => import("../screen/dashboard/Project_Services")
);
const JobDetails = lazy(() => import("../screen/viewDetails"));
const LandingPageCompanyProfile = lazy(
  () => import("../screen/LandingPage/CompanyProfile")
);

const Routers: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0DA4CE",
        light: "#f5f6fa",
      },
      secondary: {
        main: "#424546",
      },
      text: {
        primary: "#000000",
        secondary: "#100d2e",
      },
    },
    typography: {
      fontFamily: "Inter",

      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Notification />
        <Switch>
          <PublicRoute
            path={`${Utils.Pathname.LOGIN}`}
            component={Login}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.FORGOT_PASSWORD}`}
            component={ForgotPassword}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.RESEND_LINK}`}
            component={ResendLink}
            exact
          />

          <PublicRoute
            path={`${Utils.Pathname.RESET_PASSWORD}`}
            component={ResetPassword}
            exact
          />
          <PublicRoute
            path={`${Utils.Pathname.SIGNUP}`}
            component={Signup}
            exact
          />

          <PublicRoute
            path={`${Utils.Pathname.VERIFY}`}
            component={VerifyEmail}
            exact
          />

          <PublicRoute
            path={`${Utils.Pathname.COMPANYPROFILE}`}
            component={CompanyProfile}
            exact
          />

          <PublicRoute
            testing
            path={`${Utils.Pathname.TERMS}`}
            component={Terms}
            exact
          />
          <PublicRoute
            testing
            path={`${Utils.Pathname.PRIVACYPOLICY}`}
            component={PrivacyPolicy}
            exact
          />

          <PublicRoute
            testing
            path={`/landing`}
            component={LandingPage}
            exact
          />

          <PrivateRoute
            path={`${Utils.Pathname.SAVEDCOMPANY}`}
            component={SavedCompany}
          />

          <PrivateRoute
            path={`${Utils.Pathname.HEADER.DASHBOARD}`}
            component={Dashboard}
            exact
          />

          <PrivateRoute
            path={`${Utils.Pathname.SCHEDULEBOOKING}`}
            component={ViewAllScheduleBooking}
          />

          <PrivateRoute
            path={`${Utils.Pathname.Settings.base}`}
            component={Settings}
          />

          <PrivateRoute
            path={`${Utils.Pathname.HEADER.REQUESTS}`}
            component={Requests}
          />

          <PrivateRoute
            path={`${Utils.Pathname.CompanyProfile}`}
            component={LandingPageCompanyProfile}
          />

          <PrivateRoute
            path={`${Utils.Pathname.UnderDevelopment}`}
            component={Logout}
          />

          <PrivateRoute
            path={`${Utils.Pathname.MyRequest}`}
            component={MyRequest}
          />

          <PrivateRoute
            path={`${Utils.Pathname.RFQProjects}`}
            component={RFQProject}
          />

          <PrivateRoute
            path={`${Utils.Pathname.EmergencyCallOut}`}
            component={EmergencyCallout}
          />

          <PrivateRoute
            path={`${Utils.Pathname.MaintenanceServices}`}
            component={MaintenanceServices}
          />
          <PrivateRoute
            path={`${Utils.Pathname.ProjectService}`}
            component={ProjectSupport}
          />

          <PrivateRoute
            path={`${Utils.Pathname.JobDetails}`}
            component={JobDetails}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default Routers;

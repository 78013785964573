import Utils from "../../Utils";
import {
  globalLoaderStart,
  globalLoaderEnd,
} from "../../reducer/globalReducer";

export const getNotificationListData = () => {
  return (dispatch: any) => {
    const queryString = `?receiverId=${
      localStorage.getItem("roles")
        ? localStorage.getItem("clientId")
        : localStorage.getItem("user_id")
    }&pageNo=${1}&limit=${100}&userType=${"CLIENT"}`;

    // dispatch(globalLoaderStart());
    Utils.API.getApiCall(
      Utils.endPoint.notificationList,
      queryString,
      (responseData: any) => {
        const { data } = responseData;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.NOTIFICATION_LIST,
            payload: {
              notificationListData: data.data,
            },
          });
        } else {
          Utils.showAlert(2, data?.message);
        }
        // dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data?.message);
        // dispatch(globalLoaderEnd());
      }
    );
  };
};

export const updateNotification =
  (Type: number, id: any) => async (dispatch: any) => {
    const dataToSend = {
      notificationIds: id,
      type: Type,
      receiverId: localStorage.getItem("user_id"),
      userType: "CLIENT",
    };

    dispatch(globalLoaderStart());
    Utils.API.putApiCall(
      Utils.endPoint.readUnreadNotification,
      dataToSend,
      (res: any) => {
        const { data } = res;
        if (data.statusCode === Utils.Constants.api_success_code.success) {
          // dispatch(getNotificationListData());
          Utils.showAlert(1, data?.message);
        }
        dispatch(getNotificationListData());
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        console.dir(error);

        let { data } = error;
        Utils.showAlert(2, data?.message || "An Unexpected Error Occured");
        dispatch(globalLoaderEnd());
      }
    );
  };

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Header from "../../components/header";

const useStyles = makeStyles(() => ({
  container: () => ({
    width: "100%",
    // height: "100vh",
  }),
  mainContainer: () => ({
    // height: "100vh",
    width: "100%",

    flexDirection: "column",
  }),

  navAndBodyContainer: () => ({}),

  bodyContainer: () => ({
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 69px)",
    paddingTop: "68px",
  }),
}));

interface Props {
  children?: any;
  history?: any;
}
const MainContainer = ({ children }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <div className={classes.container}>
        <Header />
        {children}
      </div>
    </>
  );
};

export default MainContainer;
